<template>
  <div class="orderlist-wrap">
    <div class="bread-wrap">
      <span class="bread-title">订单管理</span>
      <download-excel
        class="export-excel-wrapper"
        v-if="tabledata && tabledata.length != 0"
        :data="tabledata"
        :fields="json_fields"
        name="订单管理.xls"
      >
        <el-button type="text" v-if="tabledata && tabledata.length != 0"
          >导出excel表格</el-button
        >
      </download-excel>
    </div>
    <div class="content">
      <div class="list-top">
        <div class="list-select">
          <span class="select-title">订单时间</span>
          <el-dropdown
            placement="bottom"
            class="select-type"
            @command="changeOrderTime"
          >
            <el-button plain type="primary">
              {{ createTimeName
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :key="index"
                v-for="(item, index) in createTime"
                v-text="item.value"
                :command="item.key"
              ></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span class="select-title">资源类型</span>
          <el-dropdown
            placement="bottom"
            class="select-type"
            @command="changeResType"
          >
            <el-button plain type="primary">
              {{ resTypeName }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :key="index"
                v-for="(item, index) in resourceType"
                v-text="item.value"
                :command="item.key"
              ></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span class="select-title">订单类型</span>
          <el-dropdown
            placement="bottom"
            class="select-type"
            @command="changeOrderType"
          >
            <el-button plain type="primary">
              {{ orderTypeName
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                :key="index"
                v-for="(item, index) in orderType"
                v-text="item.value"
                :command="item.key"
              ></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <com-table
        class="orderlist"
        :headercellstyle="headercellstyle"
        :columns="orderList"
        :data="tabledata"
        tableheight="calc(100vh - 300px)"
        :pagination="pagination"
      >
      </com-table>
    </div>
  </div>
</template>
<script>
import ComTable from "@/components/Table";
import { orderList } from "@/utils/tableList";
import { getToken } from "@/utils/auth";
export default {
  name: "orderList",
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tabledata: [],
      orderList,
      json_fields: {
        订单ID: {
          callback: (value) => {
            return "&nbsp;" + value.OrderId;
          },
        },
        订单类型: {
          callback: (value) => {
            if (value.OrderType == "NEW") {
              return "新增";
            } else if (value.OrderType == "RENEW") {
              return "续费";
            } else {
              return value.OrderType;
            }
          },
        },
        资源类型: {
          callback: (value) => {
            if (value.ResourceType == "PATH") {
              return "直播快";
            } else if (value.ResourceType == "PHONE") {
              return "云手机";
            } else {
              return value.ResourceType;
            }
          },
        },
        购买金额: "Total",
        创建时间: "CreateTime",
        完成时间: "FinishTime",
      },
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      resourceType: [
        { key: "ALL", value: "全部" },
        { key: "PHONE", value: "云手机" },
        { key: "PATH", value: "直播快" },
      ],
      orderType: [
        { key: "ALL", value: "全部" },
        { key: "NEW", value: "新增" },
        { key: "RENEW", value: "续费" },
      ],
      createTime: [
        { key: "ALL", value: "全部" },
        { key: this.getNowMonth(), value: "本月" },
        { key: this.getLastMonth(), value: "上月" },
      ],
      orderdata: [],
      resTypeName: "全部",
      orderTypeName: "全部",
      createTimeName: "全部",
      currentRes: "ALL",
      currentOrder: "ALL",
      currentTime: "ALL",
    };
  },
  components: { ComTable },
  methods: {
    getorderlist() {
      var hasToken = getToken();
      this.$store
        .dispatch("pay/getorderlist", { Authorization: hasToken })
        .then((res) => {
          //console.log("get order list: " + JSON.stringify(res));
          if (res.RetCode == 0) {
            res.Orders.forEach((item) => {
              item.Total = "¥" + item.Total / 100;
            });
            this.orderdata = res.Orders;
            this.tabledata = res.Orders;
          }
        });
    },
    getNowMonth() {
      const timeOne = new Date();
      const year = timeOne.getFullYear();
      let month = timeOne.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      const NOW_MONTHS_AGO = `${year}-${month}`;
      return NOW_MONTHS_AGO;
    },
    getLastMonth() {
      const timeOne = new Date();
      const year = timeOne.getFullYear();
      let month = timeOne.getMonth() + 1;
      if (month == 1) {
        month = month < 10 ? "0" + month : month;
        const NOW_MONTHS_AGO = `${year - 1}-${12}`;
        return NOW_MONTHS_AGO;
      } else {
        month = month - 1;
        month = month < 10 ? "0" + month : month;
        const NOW_MONTHS_AGO = `${year}-${month}`;
        return NOW_MONTHS_AGO;
      }
    },
    changeResType(command) {
      this.currentRes = command;
      if (command == "PHONE") {
        this.resTypeName = this.resourceType[1].value;
      } else if (command == "PATH") {
        this.resTypeName = this.resourceType[2].value;
      } else {
        this.resTypeName = this.resourceType[0].value;
      }
      this.handleCommand();
    },
    changeOrderType(command) {
      this.currentOrder = command;
      if (command == "NEW") {
        this.orderTypeName = this.orderType[1].value;
      } else if (command == "RENEW") {
        this.orderTypeName = this.orderType[2].value;
      } else {
        this.orderTypeName = this.orderType[0].value;
      }
      this.handleCommand();
    },

    changeOrderTime(command) {
      this.currentTime = command;
      for (var i = 0; i < this.createTime.length; i++) {
        if (command == this.createTime[i].key) {
          this.createTimeName = this.createTime[i].value;
        }
      }
      // if (command == "2023-01") {
      //   this.createTimeName = this.createTime[1].value;
      // } else if (command == "2022-12") {
      //   this.createTimeName = this.createTime[2].value;
      // } else {
      //   this.createTimeName = this.createTime[0].value;
      // }
      this.handleCommand();
    },
    handleCommand() {
      console.log(
        "res: " +
          this.currentRes +
          " order: " +
          this.currentOrder +
          "time：" +
          this.currentTime
      );
      if (
        this.currentRes == "ALL" &&
        this.currentOrder == "ALL" &&
        this.currentTime == "ALL"
      ) {
        this.tabledata = this.orderdata;
      } else if (
        this.currentRes == "ALL" &&
        this.currentOrder != "ALL" &&
        this.currentTime == "ALL"
      ) {
        this.tabledata = this.orderdata.filter(
          (item) => item.OrderType == this.currentOrder
        );
      } else if (
        this.currentRes != "ALL" &&
        this.currentOrder == "ALL" &&
        this.currentTime == "ALL"
      ) {
        this.tabledata = this.orderdata.filter(
          (item) => item.ResourceType == this.currentRes
        );
      } else if (
        this.currentRes == "ALL" &&
        this.currentOrder != "ALL" &&
        this.currentTime != "ALL"
      ) {
        this.tabledata = this.orderdata.filter(
          (item) =>
            item.OrderType == this.currentOrder &&
            item.CreateTime.includes(this.currentTime)
        );
      } else if (
        this.currentRes != "ALL" &&
        this.currentOrder == "ALL" &&
        this.currentTime != "ALL"
      ) {
        this.tabledata = this.orderdata.filter(
          (item) =>
            item.ResourceType == this.currentRes &&
            item.CreateTime.includes(this.currentTime)
        );
      } else if (
        this.currentRes == "ALL" &&
        this.currentOrder == "ALL" &&
        this.currentTime != "ALL"
      ) {
        this.tabledata = this.orderdata.filter((item) =>
          item.CreateTime.includes(this.currentTime)
        );
      } else if (
        this.currentRes != "ALL" &&
        this.currentOrder != "ALL" &&
        this.currentTime == "ALL"
      ) {
        this.tabledata = this.orderdata.filter(
          (item) =>
            item.ResourceType == this.currentRes &&
            item.OrderType == this.currentOrder
        );
      } else {
        this.tabledata = this.orderdata.filter(
          (item) =>
            item.ResourceType == this.currentRes &&
            item.OrderType == this.currentOrder &&
            item.CreateTime.includes(this.currentTime)
        );
      }
    },
    getuserinfo() {
      this.$store.dispatch("user/getuserinfo").then((res) => {
        if (res.RetCode == 0) {
        }
      });
    },
  },
  mounted() {
    this.getorderlist();
    // console.log(orderList);
    this.getuserinfo();
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>